footer {
  background: var(--color-secondary);
  padding: 3rem 0;
  text-align: center;
  color: var(--color-light);
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-primary);
}

.footer__logo {
  display: block;
  width: 10%;
  margin: 0 auto 1rem;
  #object-fit: cover;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 1rem;
}

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .permalinks {
    gap: 0.5rem;
  }
  .footer__logo {
    width: 20%;
  }
}

@media screen and (max-width: 600px) {
  .footer__logo {
    width: 45%;
  }
}
