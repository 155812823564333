nav {
  background: rgba(0,0,0,0.6);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(5px);
}

nav a {
  font-size: 1.2rem;
  padding: 0.5rem;
}
