@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;800&display=swap');

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-bg: #152812;
  #--color-bg: #191919;
  --color-bg: #000000;
  #--color-primary: #8bc307;
  --color-primary: #F9004D;
  #--color-secondary: #90b081;
  --color-secondary: #191919;
  --color-light: #e6eee3;

  /*
   * color pallete
   #152812
   #216100
   #8ab22e
   #90b081
   #e6eee3
   */

/*
  sweet red: #f9004d
  #ef0963
  #f61b10
  szary: #191919; -> dobre na guziki

  hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #f61b10, #ef0963);
  }


 pomysl jest taki by background color dla skillsow byl czarny
 a dla certyfikatow 191919

 */
  
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

* {
  marigin: 0;
  padding:0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  background: var(--color-bg);

  font-family: 'Poppins', sans-serif; 
  color: var(--color-white);
  #line-height: 1.7;
}

.container {
  width: var(--container-width-lg);
  marigin: 0 auto;
}

.text-light {
  color: var(--color-light);
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

button {
  width: max-content;
  font-size: 1rem;
  /*
  #font-family: 'Arial', sans-serif; 
  */
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 2px solid var(--color-primary);
  transition: var(--transition);
}

button:hover {
  color: var(--color-primary);
  background: transparent;
  border-color: var(--color-primary);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

section {
  margin-top: 10rem;
  #height: 100vh;
  border: 1px solid transparent;
}

section > h2 {
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 3rem;
  #margin-top: 0rem;
}

section > h5 {
  text-align: center;
  color: var(--color-light);
  margin-top: 6rem;
  #margin-bottom: 0rem;
}

/*




/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    marigin-bottom: 2rem;
  }
}


/* SMALLER DEVICES 1:04:43 */
/* SMALLER DEVICES 1:35:00 */
