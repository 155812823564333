.certifications__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; 
  gap: 8%;
  margin: auto;
  place-items: top;
  #border: 1px solid red;
}

.certifications__item {
  background: var(--color-secondary);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  #position: relative;
}

.certifications__item:hover {
  background: transparent;
  border-color: var(--color-white);
  transform: scale(1.1);
}

.certifications__item h3 {
  text-align: center;
  margin: 2rem 0 0rem;
}

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .certifications__container {
    grid-template-columns: 1fr 1fr 1fr; 
    width: var(--container-width-lg);
    #gap: 1rem;
  }

  .certifications__item h3 {
    font-size: 0.9rem;
    margin: 0.4rem 0rem 0rem 0rem;
  }
}

@media screen and (max-width: 600px) {
  .certifications__container {
    grid-template-columns: 1fr 1fr; 
    gap: 1rem;
  }
}
