.skills__container {
  margin: auto;
  border: 1px solid transparent;
  #border: 1px solid green;
  width: 40%;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

section.skills {
  background-color: var(--color-secondary);
}

.skill {
  border: 1px solid transparent;
  border-radius: 1rem;
  background-color: var(--color-bg);
  padding: 1.0rem;
  transition: var(--transition);
}

.skill:hover {
  border-color: var(--color-white);
  border-color: var(--color-primary);
  transform: scale(1.05);
}

/*
.skills > h5 {
  padding-top: 2rem;
}
*/

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .skills__container {
    width: var(--container-width-md);
  }
}

@media screen and (max-width: 600px) {
  .skills__container {
    width: var(--container-width-sm);
  }
}
