.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  margin: auto;
  place-items: center;
  #border: 1px solid red;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0deg);
}

.about__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.about__card {
  background: var(--color-secondary);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);

}
.about__card:hover {
  #background: transparent;
  #border: 1px solid var(--color-white);
  background-image: linear-gradient(90deg, #f61b10, #ef0963);
  transform: translateY(5px);
}

.about__card:hover .about__icon {
  color: var(--color-white);
}

.about__icon {
  color: var(--color-primary);
  font-size: 2rem;
}

/*
.about__card h5 {
  font-size: 1rem;
}
*/

.about__card small {
  #font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0;
  color: var(--color-light);
}

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 5%;
  }

  .about__me {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    width: max-content;
    margin: auto;
  }

  .about__card {
    padding: 1rem;
  }
}
