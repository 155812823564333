.container.contact__container {
  width:58%;
  display: grid;
  grid-template-columns: 1fr; 
  flex-direction: column;
  gap: 12%;
  margin: auto;
  #border: 1px solid blue;
  display: flex;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-secondary);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option h5 {
  #margin: 0;
  margin: 0 0 0.5rem 0;
}

.contact__option h4 {
  #margin: 0;
  margin: 0.5rem 0 0 0;
  #margin: 0 0 0.5rem 0;
}

.contact__option:hover {
  background: transparent;
}

.contact__option-icon {
  font-size: 2rem;
}

/* === FORM */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary);
  resize: none;
  color: var(--color-white);
}

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr; 
    gap: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
