.home {
  letter-spacing:3px;
  font-weight: 800;
}

.home__h1 {
  font-size: 5rem;
}

.home__h2 {
  font-size: 5rem;
}

.home__h3 {
  font-size: 3rem;
}

.home__cta {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  #justify-content: center;
}

section.home {
  background-image: url(../../assets/keyboard_02.jpg);

  background-color: #010101;
  background-size: 100%;
  background-size: contain;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 6rem 6rem 0 0;

  padding: 14rem 0 0 2rem;
  margin: 5rem 10rem 0rem 10rem;
  #border: 2px solid red;
}

.home__container {
  margin: 0rem;
  width: 100%;
}

/* === MEDIA QUERIES */
@media screen and (max-width: 1024px) {
  section.home {
    padding: 14rem 0 0 4rem;
    margin: 2rem 4rem 0rem 4rem;
  }

  .home__h1 {
    font-size: 3rem;
  }

  .home__h2 {
    font-size: 3rem;
  }

  .home__h3 {
    font-size: 1rem;
  }

  button {
    font-size: 0.7rem;
    padding: 0.5rem 0.8rem;
  }

}

@media screen and (max-width: 600px) {
  section.home {
    padding: 14rem 0 0 2rem;
    margin: 2rem 0rem 0rem 0rem;
  }
  .home__h1 {
    font-size: 1.4rem;
  }

  .home__h2 {
    font-size: 2rem;
  }

  .home__h3 {
    font-size: 0.8rem;
  }

  button {
    font-size: 0.6rem;
    padding: 0.3rem 0.6rem;
  }
}
